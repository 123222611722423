import {
  List,
  Pagination,
  Edit,
  SimpleList,
  FilterForm,
  TextInput,
  DateInput,
  FilterButton, RadioButtonGroupInput, useListContext, WithListContext, useRefresh, NumberInput
} from "react-admin"
import React, { useEffect, useMemo, useState } from "react"
import Chat from '~/components/chat/Chat'
import PersonIcon from '@mui/icons-material/Person'
import moment from "moment/moment"
import Cookies from "universal-cookie"
import { TOKEN_COOKIE_KEY } from "~/constants/auth"
import { Stack } from "@mui/material"
import _ from "lodash"
import { asyncEmit, chatSocket } from "~/modules/socket"
import { useQuery } from "@tanstack/react-query"
import { ChatItemList } from "~/components/chat/ChatItemList"

const cookies = new Cookies()

export const Chats = () => {
  const refresh = useRefresh()
  const userId = JSON.parse(atob(cookies.get(TOKEN_COOKIE_KEY).split('.')[1])).id

  useEffect(() => {
    const handleChatsList = () => {
      refresh()
    }
    chatSocket.on("chats-messages-create", handleChatsList)
    return () => {
      chatSocket.off("chats-messages-create", handleChatsList)
    }
  }, [])

  return (
    <List
      pagination={<ChatsPagination />}
      emptyWhileLoading
      filter={{
        custom: { existsMessage: true },
        users: [{ id: userId }, { id: { ne: null } }],
      }}
    >
      <ListToolbar />
      <WithListContext
        render={({ isLoading, data }) =>
          !isLoading && (
            <Stack spacing={1}>
              {data.map(chat => (
                <ChatItemList
                  key={`${chat.id}-${chat.initiatorId}`}
                  chat={chat}
                  userId={userId}
                />
              ))}
            </Stack>
          )
        }
      />
    </List>
  )
}

export const ChatsEdit = () => {
  return (
    <Edit>
      <Chat />
    </Edit>
  )
}

const ChatsPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
)

const ListToolbar = () => {

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={0}
      sx={{ ml: 2, mr: 2 }}
    >
      <FilterForm filters={[
        <TextInput label="Поиск" source="query" />,
        <TextInput label="ID" source="id" />,
        <DateInput label="Дата создания чата" source="createdAt" />,
        <NumberInput label={"Пользователи"} source={"userIdsByTypeId.id"} />,
      ]} />
      <div>
        <FilterButton filters={[
          <TextInput label="Поиск" source="query" />,
          <TextInput label="ID" source="id" />,
          <DateInput label="Дата создания чата" source="createdAt" />,
          <NumberInput label={"Пользователи"} source={"userIdsByTypeId.id"} />,
        ]}/>
      </div>
    </Stack>
  )
}